import React, { useEffect, useState } from 'react';
import banner1 from './Assets/Images/Banner1.jpg';
import banner2 from './Assets/Images/Banner2.jpg';
import banner3 from './Assets/Images/Banner3.jpg';

import Model from "../src/enquirymodel";

function BannerMain({ isModalOpen, setIsModalOpen }) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);

  const images = [banner1, banner2, banner3];
  const titles = ["Shriram Pristine Estate", "Brigade Neem Grove", "Sattva Green Grove"];
  
  // URLs for each project
  const projectUrls = [
    "https://shriramproperties.in/shriram-pristine-estates/",
    "https://www.plotinbangalore.in/property-details/brigade-neem-grove/ccaf9231-2e53-432f-b03d-c16721eb7f88",
    "https://luxuryandme.in/sattva-green-groves/"
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
        setFade(true);
      }, 1000);
    }, 5000);

    return () => clearInterval(interval);
  }, [images.length]);

  const handleDotClick = (index) => {
    setFade(false);
    setTimeout(() => {
      setCurrentIndex(index);
      setFade(true);
    }, 1000);
  };

  const handleTitleClick = () => {
    // Redirect to the URL based on the currentIndex
    window.location.href = projectUrls[currentIndex];
  };

  const handleEnquiryClick = () => {
    // If "Enquiry Now" is clicked, open the modal
    setIsModalOpen(true);
  };

  return (
    <div className="relative w-full h-96 md:h-[35rem] lg:h-[45rem] overflow-hidden md:-mt-16">
      <div
        className="absolute w-full h-96 md:h-full flex transition-transform duration-1000 ease-in-out"
        style={{ transform: `translateX(-${currentIndex * 100}%)` }}
      >
        {images.map((image, index) => (
          <div
            key={index}
            className="w-full flex-shrink-0 bg-cover bg-center relative"
            style={{ backgroundImage: `url(${image})` }}
          >
            <div className="absolute inset-0 bg-black bg-opacity-50 flex justify-center md:justify-start items-center p-6 md:p-14">
              <div
                className={`text-center transition-opacity duration-1000 ease-in-out ${fade ? 'opacity-100' : 'opacity-0'}`}
              >
                <h2
                  onClick={handleTitleClick}  // Adding the onClick for project name
                  className="text-3xl md:text-6xl font-bold text-white cursor-pointer"
                >
                  {titles[currentIndex]}
                </h2>
                <button
                  onClick={handleTitleClick}  // Keeping the Enquiry Now button behavior
                  className="mt-4 px-6 py-2 md:py-3 bg-transparent border-green-500 border-2 hover:bg-green-500 text-white font-semibold rounded-lg md:rounded-full"
                >
                  ENQUIRY NOW
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="absolute bottom-5 left-0 right-0 flex justify-center space-x-2">
        {images.map((_, index) => (
          <div
            key={index}
            className={`w-2 h-2 md:w-3 md:h-3 rounded-full ${currentIndex === index ? 'bg-green-500' : 'bg-white'}`}
            onClick={() => handleDotClick(index)}
            style={{ cursor: 'pointer' }}
          />
        ))}
      </div>
      <Model
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export default BannerMain;
